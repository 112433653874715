<template>
  <v-col class="mt-1">
    <v-col class="shades white pa-6 pb-0">
      <v-row class="px-0">
        <v-col cols="12" md="12">
          <h2 class="font-weight-bold headline hidden-sm-and-down">등록하신 광고에 대한<br />정보를 설정해주세요.</h2>
        </v-col>
        <v-col cols="12" md="10">
          <div class="body-1 font-weight-bold mt-6 mb-2">
            제품 한 줄 소개<span class="caption ml-1 rouge--text">*필수</span>
          </div>
          <div class="body-1 mb-2">제품을 한 줄로 소개해주세요.</div>
          <v-text-field
            v-model="form.abstraction"
            name="abstraction"
            type="text"
            :rules="[errors.first('abstraction') || !errors.has('abstraction')]"
            placeholder="제품 한 줄 소개"
            @input="checkDone"
            single-line
            outlined
            solo
            flat></v-text-field>
        </v-col>
        <v-col cols="12" md="10">
          <div class="body-1 font-weight-bold mb-2">
            제품 상세 소개<span class="caption ml-1 rouge--text">*필수</span>
          </div>
          <div class="body-1 mb-2">제품의 특징과 자랑을 길게 적어주세요.</div>
          <v-textarea
            v-model="form.introduction"
            name="introduction"
            type="text"
            :rules="[errors.first('introduction') || !errors.has('introduction')]"
            placeholder="제품 상세 소개"
            @input="checkDone"
            outlined
            solo
            flat></v-textarea>
        </v-col>
        <v-col cols="12" md="7">
          <div class="body-1 font-weight-bold mb-2">
            제품 배송 방식<span class="caption ml-1 rouge--text">*필수</span>
          </div>
          <div class="body-1 mb-2">제품 제공 방식을 선택해 주세요.</div>
          <v-radio-group
            v-model="form.deliveryMethod"
            @change="checkDone"
            row
            :mandatory="true"
            class="radio_group"
            hide-details>
            <!--            <v-radio v-for="[k, v] in Object.entries((option.deliveryMethod || {}))" :value="k" :label="v" :key="k"></v-radio>-->
            <v-radio label="직접 배송" value="A" class="radio pl-2" color="rouge"></v-radio>
            <v-radio label="환급금" value="PR" class="radio pl-2" color="rouge"></v-radio>
          </v-radio-group>
          <v-row v-if="form.deliveryMethod === 'PR'" class="caption mt-2" style="color: #808080"
            >구매 후 환급금 선택시 회원이 쇼핑몰에서 직접 구매합니다.</v-row
          >
        </v-col>
        <v-col class="my-2" cols="12">
          <v-row>
            <!--            <v-col cols="12" md="8" class="estimate">-->
            <!--              <bulbIcon class="light_bulb"/>-->
            <!--              <div>-->
            <!--                자사몰에서 적립금 지급이 가능한 경우, 슈퍼멤버스 회원을 자사몰 회원으로 확보할 수 있습니다.<br>-->
            <!--                또한 회원이 자사몰에서 구매 과정을 직접 거치기 때문에 후기의 내용이 더 풍부해질 수 있습니다.-->
            <!--              </div>-->
            <!--            </v-col>-->
          </v-row>
        </v-col>
        <v-col cols="12" class="my-4" v-if="form.deliveryMethod === 'PR'">
          <v-col class="my-2" cols="12" md="10">
            <div class="label font-weight-bold mb-2">쇼핑몰 링크</div>
            <div class="label mb-2">회원이 제품을 구매할 쇼핑몰 페이지 주소를 입력해주세요.</div>
            <v-text-field
              v-model="form.registerLink"
              name="registerLink"
              type="text"
              :rules="[errors.first('registerLink') || !errors.has('registerLink')]"
              placeholder="https://www.store.com/register"
              @input="checkDone"
              single-line
              outlined
              solo
              flat></v-text-field>
          </v-col>
          <v-col cols="12" class="my-2">
            <div class="label font-weight-bold mb-2">추가 요청사항</div>
            <div class="label mb-2">쇼핑몰에서 직접 구매시 추가 요청사항을 정해주세요.</div>
            <v-row>
              <v-checkbox
                v-model="form.requirement"
                @change="checkDone"
                label="텍스트 리뷰"
                color="rouge"
                value="text"
                hide-details
                class="checkbox pl-2 pt-2 mr-2 my-1"></v-checkbox>
              <v-checkbox
                v-model="form.requirement"
                @change="checkDone"
                label="포토 리뷰"
                color="rouge"
                value="photo"
                hide-details
                class="checkbox pl-2 pt-2 mr-2 my-1"></v-checkbox>
              <v-checkbox
                v-model="form.requirement"
                @change="checkDone"
                label="동영상 리뷰"
                color="rouge"
                value="video"
                hide-details
                class="checkbox pl-2 pt-2 mr-2 my-1"></v-checkbox>
              <v-checkbox
                v-model="form.requirement"
                @change="checkDone"
                label="스토어 찜(스마트스토어)"
                color="rouge"
                value="zzimStore"
                hide-details
                class="checkbox pl-2 pt-2 mr-2 my-1"></v-checkbox>
              <v-checkbox
                v-model="form.requirement"
                @change="checkDone"
                label="상품찜(스마트스토어)"
                color="rouge"
                value="zzimProduct"
                hide-details
                class="checkbox pl-2 pt-2 mr-2 my-1"></v-checkbox>
            </v-row>
          </v-col>
          <v-col class="mt-6" cols="12" md="10">
            <div class="label mb-2">쇼핑몰 리뷰에 공정위 문구 필수 삽입 여부를 선택해주세요.</div>
            <v-btn-toggle v-model="form.ftcRequired" @change="checkDone" mandatory>
              <v-btn
                :value="1"
                active-class="rouge--text text--rouge v-btn--active"
                class="ma-0"
                style="width: 130px"
                outlined
                >예</v-btn
              >
              <v-btn
                :value="0"
                active-class="rouge--text text--rouge v-btn--active"
                class="ma-0"
                style="width: 130px"
                outlined
                >아니오</v-btn
              >
            </v-btn-toggle>
            <div class="mt-2">
              <span v-if="form.deliveryMethod === 'PR' && !form.ftcRequired" class="caption rouge--text"
                >아니오를 선택한 경우에도 공정위 문구가 포함될 수 있으며, 등록된 리뷰에서 공정위 문구 제외 및 삭제
                요청은 불가능합니다.</span
              >
            </div>
          </v-col>
          <v-col cols="12" class="my-2" v-if="form.ftcRequired">
            <div class="label mt-6 mb-2">쇼핑몰 리뷰에 들어가는 공정위 문구를 입력해주세요.</div>
            <v-text-field
              v-model="form.ftcText"
              name="ftcText"
              type="text"
              v-validate="'min:2'"
              :rules="[errors.first('ftcText') || !errors.has('ftcText')]"
              placeholder="제품을 무상으로 지원받아 작성한 솔직한 후기입니다."
              persistent-hint
              @input="checkDone"
              single-line
              outlined
              hide-details></v-text-field>
          </v-col>
        </v-col>
        <v-col class="my-2" cols="12" md="10">
          <div class="label font-weight-bold mb-2">추가 질문 텍스트</div>
          <div class="label mb-2">추가로 받아야 하는 정보가 있으실 경우 질문 형식으로 입력해주세요.</div>
          <v-text-field
            v-model="form.additionalInfo"
            name="additionalInfo"
            type="text"
            :rules="[errors.first('additionalInfo') || !errors.has('additionalInfo')]"
            placeholder="원하는 색상을 입력해주세요."
            @input="checkDone"
            single-line
            outlined
            solo
            flat></v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <v-col class="shades white px-6">
      <v-row class="px-0">
        <v-col class="my-2" cols="12" md="10" v-show="form.deliveryMethod === 'A'">
          <div class="label font-weight-bold mb-2">제품 발송 요일</div>
          <div class="label mb-2">제품이 발송되는 요일을 알려주세요.</div>
          <v-text-field
            v-model="form.shipping"
            name="shipping"
            type="text"
            :rules="[errors.first('shipping') || !errors.has('shipping')]"
            placeholder="15시 이전 주문 시 당일발송됩니다. / 매주 화,목요일 발송됩니다. / .."
            @input="checkDone"
            single-line
            outlined
            solo
            flat></v-text-field>
        </v-col>
        <v-col class="my-2" cols="12" md="10" v-show="form.deliveryMethod === 'C'">
          <div class="label font-weight-bold mb-2">회원가입 주의사항</div>
          <div class="label mb-2">회원가입 후 주의사항이 있다면 입력해주세요.</div>
          <v-text-field
            v-model="form.registerAlert"
            name="registerAlert"
            type="text"
            :rules="[errors.first('registerAlert') || !errors.has('registerAlert')]"
            placeholder='가입 후 "스토어찜"과 "톡톡친구"를 꼭 눌러주셔야 쿠폰 발급이 가능합니다.'
            @input="checkDone"
            single-line
            outlined
            solo
            flat></v-text-field>
        </v-col>
      </v-row>
    </v-col>
  </v-col>
</template>

<script>
export default {
  name: 'Page3',
  props: ['adsData', 'option'],
  components: {
    // bulbIcon: () => import('@/assets/img/regads/light-bulb.svg')
  },
  data: function () {
    return {
      form: {
        delivery: this.adsData.delivery || [],
        abstraction: this.adsData.abstraction || '',
        introduction: this.adsData.introduction || '',
        deliveryMethod: this.adsData.deliveryMethod || 'A',
        additionalInfo: this.adsData.additionalInfo || '',
        shipping: this.adsData.shipping || '',
        registerLink: this.adsData.registerLink || '',
        registerAlert: this.adsData.registerAlert || '',
        requirement: this.adsData.requirement || [],
        ftcRequired: this.adsData.ftcRequired,
        ftcText: this.adsData.ftcText || '제품을 무상으로 지원받아 작성한 솔직한 후기입니다.',
      },
    };
  },
  watch: {
    adsData(value) {
      this.form = {
        delivery: value.delivery || [],
        abstraction: value.abstraction || '',
        introduction: value.introduction || '',
        deliveryMethod: value.deliveryMethod || 'A',
        additionalInfo: value.additionalInfo || '',
        shipping: value.shipping || '',
        registerLink: value.registerLink || '',
        registerAlert: value.registerAlert || '',
        requirement: value.requirement || [],
        ftcRequired: value.ftcRequired,
        ftcText: value.ftcText || '제품을 무상으로 지원받아 작성한 솔직한 후기입니다.',
      };
    },
    'form.delivery': {
      handler: function (newValue) {
        if (newValue[newValue.length - 1] === 'address') {
          let idx = this.form.delivery.indexOf('id');
          if (idx > -1) {
            this.form.delivery.splice(idx, 1);
          }
          idx = this.form.delivery.indexOf('email');
          if (idx > -1) {
            this.form.delivery.splice(idx, 1);
          }
        } else if (newValue[newValue.length - 1] === 'id') {
          let idx = this.form.delivery.indexOf('address');
          if (idx > -1) {
            this.form.delivery.splice(idx, 1);
          }
          idx = this.form.delivery.indexOf('email');
          if (idx > -1) {
            this.form.delivery.splice(idx, 1);
          }
        } else if (newValue[newValue.length - 1] === 'email') {
          let idx = this.form.delivery.indexOf('address');
          if (idx > -1) {
            this.form.delivery.splice(idx, 1);
          }
          idx = this.form.delivery.indexOf('id');
          if (idx > -1) {
            this.form.delivery.splice(idx, 1);
          }
        }
      },
      deep: false,
    },
    'form.deliveryMethod': {
      handler: function (newValue) {
        if (newValue === 'A') {
          let idx = this.form.delivery.indexOf('id');
          if (idx > -1) {
            this.form.delivery.splice(idx, 1);
          }
          idx = this.form.delivery.indexOf('email');
          if (idx > -1) {
            this.form.delivery.splice(idx, 1);
          }
          this.form.delivery.push('address');
        } else if (newValue === 'C') {
          let idx = this.form.delivery.indexOf('address');
          if (idx > -1) {
            this.form.delivery.splice(idx, 1);
          }
        }
      },
    },
    'form.requirement': function (value) {
      if (
        value.length > 0 &&
        (value[value.length - 1] == 'text' || value[value.length - 1] == 'photo' || value[value.length - 1] == 'video')
      ) {
        this._.remove(this.form.requirement, item => {
          return ['text', 'photo', 'video'].indexOf(item) > -1 && item !== value[value.length - 1];
        });
      }
    },
  },
  methods: {
    checkDone() {
      this.$validator.validateAll().then(success => {
        if (success) {
          // if(this.form.deliveryMethod && this.form.delivery) {
          //   this.$emit('updateEvent', this._.defaults(this.form, this.adsData));
          this.$emit('updateEvent', this.form);
          this.$store.commit('setDirty', true);
          // }
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../../assets/scss/custom';

.checkbox {
  border: 1px solid;
  border-radius: 8px;
  max-width: 230px;
  width: 100%;
  height: 40px;
  margin-top: 0px;
}

.radio_group {
  margin-top: 0px;
}

.radio {
  border: 1px solid;
  border-radius: 8px;
  max-width: 130px;
  height: 40px;
  margin-top: 0px;
  width: 130px;
}

.radio.v-item--active {
  border-color: var(--rouge-color);
}
</style>
